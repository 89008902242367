import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { css } from 'styled-components'

import Layout from '../components/Layout'

import { fontSizes, space, breakpoints } from '../utils/tokens'

const Campaigns =  ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main
        css={css({
          minHeight: `100vh`,
        })}
      >
        <section
          css={css({
            paddingLeft: `${space[4]}px`,
            paddingRight: `${space[4]}px`,
            maxWidth: breakpoints.xl,
            margin: `0 auto`,
          })}
        >
          <h1
            css={css({
              fontSize: fontSizes[5],
              fontWeight: `bold`, 
              marginBottom: `${space[3]}px`,
            })}
          >All campaigns</h1>
          <ul>
            {data.allContentfulCampaignLandingPage.edges.map(({ node }) => {
              return (
                <li key={node.id}
                  css={css({
                    fontSize: fontSizes[3],
                  })}
                >
                  <Link to={node.fields.path}>{node.name}</Link>
                </li>
              )
            })}
          </ul>
        </section>
      </main>
    </Layout>
  )
}

export default Campaigns

export const campaignsQuery = graphql`
  query {
    allContentfulCampaignLandingPage {
      edges {
        node {
          id
          name
          hero {
            ...HeroFragment
          }
          fields {
            path
          }
        }
      }
    }
  }
`